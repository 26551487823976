<template>
  <div class="NavigationBar">
    <b-navbar
      toggleable="lg"
      type="light"
      class="border-bottom"
      style="background-color: #f6f6f6"
    >
      <b-navbar-brand href="/">
        <img
          src="../assets/images/abpools-brandmark.svg"
          alt="abpools"
          width="40"
        />
        AB Pools
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- left aligned menu items -->
        <b-navbar-nav>
          <b-nav-item to="/">Home</b-nav-item>

          <b-nav-item-dropdown text="Pools" right>
            <b-dropdown-item href="/pools/new-projects"
              >New Projects</b-dropdown-item
            >
            <b-dropdown-item href="/pools/service">Service</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Spas" right>
            <b-dropdown-item href="/spas/installation"
              >Installation</b-dropdown-item
            >
            <b-dropdown-item href="/spas/service">Service</b-dropdown-item>
          </b-nav-item-dropdown>

          <!-- <b-nav-item to="/Residential">Spas</b-nav-item> -->
          <!-- <b-nav-item to="/Gallery">Gallery</b-nav-item> -->
          <b-nav-item to="/about">About</b-nav-item>
          <b-nav-item to="/contact">Contact Us</b-nav-item>
          <b-nav-item to="/signin" v-show="!this.$store.state.sessionExists">Sign In</b-nav-item>
          <b-nav-item @click="logOut" v-show="this.$store.state.sessionExists">Sign Out</b-nav-item>
          <b-nav-item to="/admin" v-show="this.$store.state.sessionExists">Admin</b-nav-item>
        </b-navbar-nav>

        <!-- right aligned menu items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/contact"
            ><b-button pill variant="info"
              >Get a Free Quote<span class="pl-2"
                ><i class="fas fa-envelope"></i></span></b-button
          ></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  
  components: {},
  
  data() {
    return {
    }
  },

  methods: {
    logOut() {
      this.$cookies.remove("ab_pools", "/", process.env.VUE_APP_TOKEN_DOMAIN);
      this.$store.commit('setSession');
      this.$router.push({name:'Home'});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-brand {
  font-family: "Anton", sans-serif;
  font-size: 1em * 1.5;
}

.nav-item {
  font-family: "Roboto", sans-serif;
}
.dropdown:hover > .dropdown-menu {
  display: block;
}
</style>
