<template>
  <footer class="footer">
    <b-container fluid>
      <b-row class="pt-5">
        <b-col class="text-center" offset="3" cols="6">
          <p class="logo">AB POOLS LLC.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center" offset="2" cols="8">
          <a class="brnd-lnk" href="http://www.facebook.com/abpoolsva"
            ><i class="fab fa-facebook-square fa-2x"></i
          ></a>
          <a class="brnd-lnk" href="https://instagram.com/abpoolsva"
            ><i class="fab fa-instagram fa-2x ml-3"></i
          ></a>
          <a class="brnd-lnk" href="https://www.houzz.com/pro/abpoolsva"
            ><i class="fab fa-houzz fa-2x ml-3"></i
          ></a>
          <a
            class="brnd-lnk"
            href="https://www.youtube.com/channel/UC__zgarfyK63DsKuOCb78SA"
            ><i class="fab fa-youtube fa-2x ml-3"></i
          ></a>
          <a
            class="brnd-lnk"
            href="https://www.yelp.com/biz/ab-pools-fredericksburg"
            ><i class="fab fa-yelp fa-2x ml-3"></i
          ></a>
        </b-col>
      </b-row>
      <b-row class="pb-5 pt-3">
        <b-col class="copyright text-center pt-3" offset="3" cols="6">
          © ABPoolsVA.com 2020. All Rights Reserved.
          <a href="/Privacy">Privacy Policy</a>.
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {}
};
</script>

<style scoped lang="scss">
.logo {
  font-family: "Anton", sans-serif;
  font-size: $base-font-size * 1.7;
  color: #e3dbd4;
}

.footer {
  background: #282b31;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.brnd-lnk {
  color: #e3dbd4;
}

.copyright {
  font-size: $base-font-size * 0.6;
  font-family: "Roboto", sans-serif;
  color: #e3dbd4;
  a {
    color: #e3dbd4;
    // font-weight: bold;
    text-decoration: none;
  }
  a:hover {
    color: #e1e1e1;
    text-decoration: underline;
  }
}
</style>
