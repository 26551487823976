// Javascript packages
import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import jQuery from "jquery";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vueCookies from "vue-cookies";



// jquery shorthand
window.$ = window.jQuery = jQuery;

// bootstrap inclusion
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex);
Vue.use(vueCookies);


Vue.config.productionTip = false;

// css
import "./assets/scss/app.scss";

// fontawesome library (svg)
import "@fortawesome/fontawesome-free/js/all.js";

const store = new Vuex.Store({
  state: {
    sessionExists: false
  },
  mutations: {
    setSession(state) {
      if(Vue.$cookies.isKey("ab_pools")) 
        state.sessionExists = true;
      else
        state.sessionExists = false;
    }
  }
});


// init vue 2x instance
new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount("#app");
