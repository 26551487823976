<template>
  <div class="info-banner" v-if="!isHidden">
    <b-container fluid class="bg-danger text-white py-2">
      <b-row>
        <b-col class="text-center banner-text">
          <span class="font-weight-bold">COVID-19 Update:</span> We are
          committed to the health and safety of our community during these
          unprecedented times. Click <b-link href="/Covid">here</b-link> to
          learn more.
          <span>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="isHidden = true"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "InfoBanner",

  components: {},

  data() {
    return {
      isHidden: false
    };
  },

  methods: {
    showInfo() {
      this.isHidden = false;
    },
    hideInfo() {
      this.isHidden = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

a:hover {
  color: #e1e1e1;
}

.banner-text {
  font-family: "Roboto", sans-serif;
  font-size: $base-font-size * 0.75;
}

button:focus {
  outline: none;
  box-shadow: none;
}
</style>
